/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, Flex, Icon } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import Close16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close16';

import useQueryParams from '../../helpers/queryParams';

const MaintenanceBanner = () => {
  const { maintenance_start_time_local } = useQueryParams();
  const [showBanner, setShowBanner] = useState(maintenance_start_time_local !== undefined);
  const closeBanner = () => setShowBanner(false);

  return (
    <>
      <SelectiveSpritesheet icons={[Close16]} />
      {showBanner && (
        <Flex
          display={showBanner ? 'flex' : 'none'}
          py={[5, 5, 0]}
          pl={7}
          // @ts-expect-error custom yellow
          backgroundColor="#fff9e6"
          flexDirection="row"
          justifyContent={[null, null, 'space-between']}
          mx="auto"
        >
          <Box
            maxWidth="80%"
            p={[4, 4, 4, 7]}
            fontSize={['s1', 's1', 's1', 's2']}
            fontWeight="semibold"
          >
            Opendoor will be down for scheduled maintenance on {maintenance_start_time_local}
          </Box>
          <Button
            variant="ghost"
            onClick={closeBanner}
            id="maintenance-banner-close-button"
            aria-label="Close banner"
            analyticsName="cosmos-maintenance-banner-close"
            p={7}
          >
            <Icon name="close" size={16} aria-hidden="true" />
          </Button>
        </Flex>
      )}
    </>
  );
};

export default MaintenanceBanner;
